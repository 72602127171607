'use client'

import { useCallback } from 'react'
import { useEffectOnce } from 'react-use'

import { APP_AUTH_ACCESS } from '@/constants/configs'
import { useLoader } from '@/hooks'
import { AuthService } from '@/services'
import { cookie } from '@/utils/storage'

export default function Bootstrap() {
  // __STATE's
  const loader = useLoader()

  // __FUNCTION's
  const starter = useCallback(async () => {
    const auth = cookie.get('play-api-session') || cookie.get(APP_AUTH_ACCESS)
    console.log({
      0: cookie.get('play-api-session'),
      1: cookie.get(APP_AUTH_ACCESS)
    })

    if (auth) {
      await AuthService.profile()
    } else {
      if (location.pathname !== '/') location.assign('/')
    }

    loader.off()
  }, [])

  // __EFFECT's
  useEffectOnce(() => {
    starter()

    setInterval(() => {
      starter()
    }, 6e4)
  })

  // __RENDER
  return null
}
